.garbage-icon {
    height: 8vmin;
    text-align: left;
    cursor:pointer
  }
  .dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
  }
  
  .image {
    width: 300px;
  }

  .hover {
    position: relative;
  
    &:hover &__no-hover {
      opacity: 0;
    }
  
    &:hover &__hover {
      opacity: 1;
    }
  
    &__hover {
      position: absolute;
      top: 0;
      opacity: 0;
    }
  
    &__no-hover {
      opacity: 1;
    }
  }