/* .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color:#f1f1f1!important;  
    text-align: center;
  } */
  .footer-basic-centered {
    background-color: #292c2f;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font: normal 18px sans-serif;
    padding: 45px;
}
.footer-company-motto {
  color: #8d9093;
  font-size: 24px;
  margin: 0;
}
.footer-links {
  list-style: none;
  font-weight: bold;
  color: #ffffff;
  padding: 35px 0 23px;
  margin: 0;
}
.footer-company-name {
  color: #8f9296;
  font-size: 14px;
  margin: 0;
}