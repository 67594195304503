
.App-logo {
  height: 13vmin;
  text-align: left;
}

.App-header {
  background-color: #f1f1f1;
    color: #ffffff;
    z-index: 1;
    width: 100%;
    top: -1;
    left: 0;
}
.App-link {
  color: #a7d309;
}
.App-content {
  z-index: 1;
  width: 100%;
  text-align: center;
}
