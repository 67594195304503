.tab-list {
    /* border-bottom: 1px solid #ccc; */
    padding-left: 0;
    margin-top: 0;
    /* margin-bottom: 0rem; */
  }
  
  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    margin-top: 30px;
    padding: 0.3rem 0.75rem;
    color: #00a2c8;
  }
  
  .tab-list-active {
    /* background-color: rgb(183, 196, 66); */
    /* border: solid #ccc; */
    /* border-width: 1px 1px 0 1px; */
    border-bottom: 4px solid #049fd9;
  }
  .test{
    color: #00a2c8;
    display: block;
    padding: 5px 15px;
    text-decoration: none;
  }
  dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0rem;
}
/* ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
} */