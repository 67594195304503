.loader {
    text-align: center;
    margin-top: 20%;
}
.city {
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
    font-weight: bold;
}
.city-info {
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
    /* font-weight: bold; */
}
.left-bar-header {
    text-align: center;
    margin-bottom: 3%;
    margin-top: 2%;
    font-weight: bold;
}