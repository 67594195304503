body,h1,h2,h3,h4,h5 {font-family: "Raleway", sans-serif}
.map-image {
    max-width: 100%;
    max-height: 50%;
    width: 100%;
    height: 300px;
    display: block;
}
.w3-width {
    max-width:1400px
}
.mission-text {
    color: darkviolet;
}